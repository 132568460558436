import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-blue-500 py-4 w-full">
      <div className="container mx-auto flex flex-col md:flex-row gap-4 justify-between items-center text-white text-sm">
        <span>Todos os direitos reservados ®</span>
        <div className="flex flex-col md:flex-row gap-4 items-center">
          <Link to="/funcionalidades">Planos e preços</Link>
          <Link to="/central-de-ajuda">Central de Ajuda</Link>
          <Link to="/politica-de-privacidade">Política de Privacidade</Link>
          <Link to="/termos-de-uso">Termos de Uso</Link>
          <Link to="/contato">Contato</Link>
        </div>
        <div className="flex items-center">
          <span>#wedriveyourtech</span>
          <img src="https://i.postimg.cc/ZKdVG9vs/Logo-Inferior-MH.png" alt="Logo" className="h-28" />
        </div>
      </div>
    </footer>
  );
};

export default Footer;

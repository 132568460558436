// src/components/RepresentativeSection.js
import React from 'react';

const RepresentativeSection = () => {
  return (
    <section className="py-10 bg-white">
      <div className="flex justify-center p-2 ">
        <h2 className="text-3xl font-bold mb-4 md:inline flex flex-col text-center">
          SEJA UM <span className="bg-blue-500 text-white px-2 rounded-[8px]">REPRESENTANTE</span> GARAGE HERO!
        </h2>
      </div>
      <div className="container mx-auto flex flex-col md:flex-row items-center gap-3">
        <div className="w-full md:w-1/2 mb-8 md:mb-0 text-center md:text-left">
          <img 
            src="https://i.postimg.cc/fyfRpnnp/Seja-um-representante.png" 
            alt="Seja um Representante Garage Hero!" 
            className="md:ml-44 mx-auto rounded-lg max-w-72 md:max-w-96"
          />
        </div>
        <div className="w-full md:w-1/2 ">
          <p className="mb-4 text-left">Se você tem <strong>experiência no setor</strong> e conhece algumas <br /> empresas que podem se interessar pelo sistema GarageHero, <br /> fale com nosso time e avalie ser nosso <strong>representante regional</strong>.</p>
          <p className="mb-4 text-left">Relacionamento e <strong>renda extra</strong> para você!</p>
          <div className='text-left'>
            <a href="https://wa.me/5511934099298?text=Ol%C3%A1%2C%20estou%20no%20site%20do%20GarageHero%20e%20desejo%20ajuda" className="underline">Fale conosco!</a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RepresentativeSection;

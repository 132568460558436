// src/components/SupportSection.js
import React from 'react';

const supportItems = [
  {
    icon: (
      <img src="https://i.postimg.cc/DZcP3WqJ/Duvidas.png" alt="" />
    ),
    title: "PERGUNTAS FREQUENTES",
    description: "Entre em nossa página de FAQ com as <strong>principais perguntas e respostas</strong> para resolver as suas dúvidas de forma rápida e 100% online",
    button: "ACESSAR",
    link: "/central-de-ajuda",
  },
  {
    icon: (
      <img src="https://i.postimg.cc/Mpj0K378/Suporte.png" alt="" />
    ),
    title: "VÍDEOS EXPLICATIVOS",
    description: "Assista aos vídeos que preparamos para explicar os <strong>principais conceitos e funcionalidades</strong> do sistema. Conteúdo simples e online",
    button: "ACESSAR",
    link: "https://www.youtube.com/playlist?list=PLgWt72nZ0jaVywuKWIwbXJpI8SjGE3S8s",
  },
  {
    icon: (
      <img src="https://i.postimg.cc/7h60vKHd/Atendimento.png" alt="" />
    ),
    title: "FALE CONOSCO",
    description: "Fale com nosso <strong>time de especialistas</strong> e agende um horário para tratar de dúvidas relacionadas ao sistema, treinamentos ou migração",
    button: "ACESSAR",
    link: "https://wa.me/5511934099298?text=Ol%C3%A1%2C%20estou%20no%20site%20do%20GarageHero%20e%20desejo%20ajuda",
  },
];

const SupportSection = () => {
  return (
    <section className="py-10 bg-gray-100">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold mb-6">
          DÚVIDAS, SUPORTE E <span className="bg-blue-500 text-white px-2 rounded-[8px]"> ATENDIMENTO </span>
        </h2>
        <div className="flex flex-col md:flex-row justify-center items-center md:space-x-6">
          {supportItems.map((item, index) => (
            <div key={index} className="p-6 bg-white rounded-lg shadow-md mb-6 md:mb-0 text-left max-w-sm">
              <div className="flex justify-center mb-4">
                {item.icon}
              </div>
              <h3 className="text-xl font-semibold mb-2">{item.title}</h3>
              <p className="mb-4" dangerouslySetInnerHTML={{ __html: item.description }}></p>
              <div className='flex justify-center'>
                <a href={item.link} className="bg-blue-500 w-fit text-white px-4 py-2 rounded-lg flex justify-center items-center text-center" dangerouslySetInnerHTML={{ __html: item.button }}></a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default SupportSection;

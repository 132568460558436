import React, { useState, useEffect } from 'react';

const FloatingWhatsAppButton = () => {
  const [marginBottom, setMarginBottom] = useState('mb-16');

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (consent) {
      setMarginBottom('mb-8');
    }

    // Adiciona um ouvinte para o evento personalizado
    const handleCookieConsent = () => setMarginBottom('mb-0');

    window.addEventListener('cookieConsentAccepted', handleCookieConsent);

    // Remove o ouvinte quando o componente for desmontado
    return () => {
      window.removeEventListener('cookieConsentAccepted', handleCookieConsent);
    };
  }, []);

  return (
    <a
      href="https://wa.me/5511934099298?text=Ol%C3%A1%2C%20estou%20no%20site%20do%20GarageHero%20e%20desejo%20ajuda"
      target="_blank"
      rel="noopener noreferrer"
      className={`fixed bottom-4 right-4 z-50 ${marginBottom}`}
    >
      <img src="https://i.postimg.cc/8kR5NQ1H/WhatsApp.png" alt="WhatsApp" className="h-16 w-16" />
    </a>
  );
};

export default FloatingWhatsAppButton;

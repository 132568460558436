// src/components/HeroSection.js
import React from 'react';

const HeroSection = () => {
  return (
    <section className="bg-blue-100 py-10">
      <div className="container mx-auto flex flex-col lg:flex-row items-center justify-between px-4">
        <div className="lg:w-1/2 mb-10 lg:mb-0 text-left">
          <h1 className="text-3xl md:text-4xl font-bold text-black mb-4">
            O SOFTWARE DE GESTÃO <br /> <span className="bg-blue-500 text-white px-2 rounded-[8px]">MAIS MODERNO</span> DO <br />SETOR AUTOMOTIVO.
          </h1>
          <p className="text-gray-700 mb-6 text-left">
            Teste agora e surpreenda-se como podemos elevar <br /> a sua experiência na gestão de sua empresa
          </p>
          <div className="flex space-x-4">
            <div className="text-black flex items-center">
              <img src="https://i.postimg.cc/Hn4jj5SH/Topo-Estrela.png" alt="" srcset="" />
              {/* <span>Feature 1</span> */}
            </div>
            <div className="text-black flex items-center">
              <img src="https://i.postimg.cc/X74pmHJv/Topo-Seguran-a.png" alt="" srcset="" />
              {/* <span>Feature 2</span> */}
            </div>
            <div className="text-black flex items-center">
              <img src="https://i.postimg.cc/1Rp8WhyT/Topo-Rapidez.png" alt="" srcset="" />
              {/* <span>Feature 3</span> */}
            </div>
          </div>
        </div>
        <div className="lg:w-1/2 flex justify-center">
          <img src="https://i.postimg.cc/wj0ZnCHH/Banner-Superior-apenas-imagem-1.png" alt="Devices" />
          
        </div>
      </div>
    </section>
  );
}

export default HeroSection;

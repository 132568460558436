// src/components/FlashComponent.js
import React from 'react';

const FlashComponent = () => {
  return (
    <div className="bg-blue-900 text-white text-center p-3 text-sm fixed w-full top-0 z-[9999999]">
      TESTE GRÁTIS POR 14 DIAS {'>'} SEM CARTÃO, SEM FIDELIDADE!
    </div>
  );
};

export default FlashComponent;

import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import CarouselSection from './components/CarouselSection';
import HighlightsSection from './components/HighlightsSection';
import CompaniesProfileSection from './components/CompaniesProfileSection';
import TestimonialsSection from './components/TestimonialsSection';
import BenefitsSection from './components/BenefitsSection';
import PricingSection from './components/PricingSection';
import BusinessPlansSection from './components/BusinessPlansSection';
import NewsSection from './components/NewsSection';
import RepresentativeSection from './components/RepresentativeSection';
import SupportSection from './components/SupportSection';
import Footer from './components/Footer';
import FloatingWhatsAppButton from './components/FloatingWhatsAppButton';
import Separator from './components/Separetor';
import FlashComponent from './components/FlashComponent';
import PricingPage from './pages/PricingPage';
import HelpCenter from './pages/HelpCenter';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';
import ContactPage from './pages/ContactPage';
import CookieConsent from './components/CookieConsent';
import ArticlePage from './pages/ArticlePage';

function App() {
  const [cookiesAccepted, setCookiesAccepted] = useState(false);

  const handleAcceptCookies = () => {
    setCookiesAccepted(true);
  };

  return (
      <Router>
        <div className="flex flex-col min-h-screen">
          <Header />
          <FlashComponent />
          <main className="flex-grow">
            <Routes>
              <Route path="/" element={
                <>
                  <HeroSection />
                  <CarouselSection />
                  <Separator />
                  <HighlightsSection />
                  <CompaniesProfileSection />
                  <TestimonialsSection />
                  <BenefitsSection />
                  <Separator />
                  <PricingSection />
                  <Separator />
                  <BusinessPlansSection />
                  <NewsSection />
                  <RepresentativeSection />
                  <SupportSection />
                </>
              } />
              <Route path="/funcionalidades" element={<PricingPage />} />
              <Route path="/central-de-ajuda" element={<HelpCenter />} />
              <Route path="/central-de-ajuda/artigo/:id" element={<ArticlePage />} />
              <Route path="/politica-de-privacidade" element={<PrivacyPolicy />} />
              <Route path="/termos-de-uso" element={<TermsOfUse />} />
              <Route path="/contato" element={<ContactPage />} />
            </Routes>
          </main>
          <Footer />
          <FloatingWhatsAppButton cookiesAccepted={cookiesAccepted} />
          <CookieConsent onAccept={handleAcceptCookies} />
        </div>
      </Router>
  );
}

export default App;
// src/components/PricingSection.js
import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './PricingSection.css';

const plans = [
  {
    title: 'Básico',
    price: 'R$ 49,99',
    duration: 'por mês',
    features: [
      'Gerenciamento simplificado: Cadastre clientes, veículos, produtos e serviços com facilidade.',
      'Compartilhamento via WhatsApp: Envie Ordens de Serviço (O.S.) diretamente para seus clientes.',
      'Aprovação de orçamento online: Seu cliente poderá aprovar remotamente o início do serviço',
      'Cobrança online: Receba pagamentos remotamente via cartão de crédito através de link de pagamento com taxas competitivas.',
      'Consulta de veículos: Obtenha informações completas do veículo cadastrado apenas com a placa.',
      'Lembrete de aniversário de seus clientes',
      'Integração com Peça Peças: Acesse um catálogo com mais de 7 milhões de peças automotivas.',
      'Controle financeiro: Gerencie contas a pagar, a receber, e tenha o controle de caixa na palma da sua mão.'
    ]
  },
  {
    title: 'Profissional',
    price: 'R$ 139,99',
    duration: 'Gratis por até 14 dias',
    features: [
      'Plano Básico: Inclui todas as funcionalidades essenciais do plano básico',
      'Acesso para equipe: Permite múltiplos logins simultâneos (até 3 usuários).',
      'Abertura de OS com checklist completo identificando as principais informações e fotos do veículo recebido',
      'Maquininha de cartão: Enviamos para você uma máquina de cartão com taxas competitivas para sua conveniência (consulte para mais detalhes).',
      'Relatórios completos: Visualize o desempenho de clientes, produtos e serviços mais vendidos. E muito mais!',
      'Área do cliente personalizada: Forneça a seu cliente o acompanhamento de serviços em andamento, serviços pendentes com lembretes e histórico completo de revisões já realizadas.',
      'Gestão de agenda: Veja em formato de calendário tudo o que acontece na sua empresa. Organize suas contas, datas importantes, agende serviços e crie lembretes automáticos.',
      'Emissão de notas fiscais: Nota de produto (NF-e) e nota de serviço (NFS-e) emitidas automaticamente pela plataforma após a criação de uma OS.',
      'Kanban 4K: Monitore e compartilhe o progresso dos serviços com seus funcionários e clientes em uma tela de TV.'
    ],
    popular: true
  },
  {
    title: 'Para empresas',
    price: 'R$ 239,99',
    duration: 'por mês',
    features: [
      'O plano mais completo: Inclui todas as funcionalidades dos planos Básico e Profissional.',
      'Controle de acesso por colaborador: configure quais acessos cada colaborador deve ter no sistema.',
      'Acesso ampliado para equipes: Permite até 10 usuários cadastrados no sistema e com acesso a área logada (para mais usuários cadastrados, consulte nosso time comercial)',
      'Função PDV: Ideal para empresas que fazem venda de peças no balcão ou online, sem depender de um serviço ou veículo.',
      'Etiqueta de óleo personalizada: Solicite uma impressora térmica para etiquetas com QR Code para acesso à área do cliente.',
      'Garage Card exclusivo: Histórico do veículo acessível online para seus clientes, de forma personalizada (consulte valores adicionais)',
      'Peça Crédito: Linha de crédito para a compra de peças (sujeito a análise).',
      'Consulte nosso time para ferramentas de CRM, email marketing, sites próprios e integração com Mercado Livre ou criação do seu e-commerce próprio (consulte valores adicionais)'
    ]
  }
];

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const PricingSection = () => {
  return (
    <section className="py-10">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold mb-4">
          O <span className="bg-blue-500 text-white px-2 rounded-[8px]">MELHOR PLANO</span> PARA VOCÊ ESTÁ AQUI!
        </h2>
        <p className="mb-8">Temos 3 planos diferentes que se adaptam a sua necessidade. Escolha e compare os planos aqui. Teste por 14 dias grátis.
        </p>
        <Carousel 
          responsive={responsive} 
          infinite={true}
          autoPlay={false}
          autoPlaySpeed={3000}
          keyBoardControl={false}
          showDots={false}
          arrows={true}
        >
          {plans.map((plan, index) => (
            <a 
              key={index} 
              href="https://sistema.garagehero.com.br/registrar" 
              target="_blank" 
              rel="noopener noreferrer"
              className={`pricing-card p-6 rounded-lg shadow-md mb-6 md:mb-0 ${plan.popular ? 'popular-plan' : ''}`}
            >
              <h3 className="text-2xl font-semibold mb-2">{plan.title}</h3>
              <p className="text-3xl font-bold text-blue-500">{plan.price}</p>
              <p className="text-sm mb-4">{plan.duration}</p>
              <ul className="text-left mb-4 no-bullet">
                {plan.features.map((feature, i) => (
                  <li key={i} className="mb-2">{feature}</li>
                ))}
              </ul>
              {plan.popular && <div className="popular-label">PLANO MAIS POPULAR</div>}
            </a>
          ))}
        </Carousel>
        <a href="/funcionalidades"><p className="mt-8 text-blue-500">Clique aqui para conhecer nossos planos</p></a>
      </div>
    </section>
  );
};

export default PricingSection;

// src/components/CompaniesProfileSection.js
import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './CompaniesProfileSection.css';

const carouselItems = [
  {
    image: 'https://i.postimg.cc/KzkjkRX4/1-Oficina-e-Autocenter.png',
    title: 'Oficinas Mecânicas & Auto Centers',
  },
  {
    image: 'https://i.postimg.cc/4yx8Qk5K/2-Autopecas-e-Acessorios.jpg',
    title: 'Auto Peças & Acessórios',
  },
  {
    image: 'https://i.postimg.cc/nL57P8pj/3-Servi-os-R-pidos.png',
    title: 'Serviços Rápidos',
  },
  {
    image: 'https://i.postimg.cc/mrQR0CGY/4-Funilaria-e-Pintura.png',
    title: 'Funilaria e Pintura',
  },
  {
    image: 'https://i.postimg.cc/3wRDLY09/6-Loja-de-Pneus.png',
    title: 'Loja de Pneus',
  },
  {
    image: 'https://i.postimg.cc/Hn4xYh7V/7-Borracharia.png',
    title: 'Borracharia',
  },
  {
    image: 'https://i.postimg.cc/wMPWBw8Y/5-Est-tica-Automotiva.png',
    title: 'Estética Automotiva',
  },
  {
    image: 'https://i.postimg.cc/43qbVm2p/8-Lava-Rapido.png',
    title: 'Lava-rápido',
  },
  {
    image: 'https://i.postimg.cc/Bb6S3nsw/9-Estacionamento.png',
    title: 'Estacionamentos',
  },
  {
    image: 'https://i.postimg.cc/sXfyPgBD/10-Blindadora.png',
    title: 'Blindadoras',
  },
  {
    image: 'https://i.postimg.cc/bw0K6jfG/11-Concessionaria.jpg',
    title: 'Concessionárias',
  },
  {
    image: 'https://i.postimg.cc/XXSz8s2c/11-Loja-de-Veiculos.png',
    title: 'Revenda de veículos',
  },
  {
    image: 'https://i.postimg.cc/sxFk6fxh/12-Locadora-e-Frotista.pngXSz8s2c/11-Loja-de-Veiculos.png',
    title: 'Locadoras e Frotistas',
  },
  {
    image: 'https://i.postimg.cc/dV0x9Z6f/13-Despachante.png',
    title: 'Despachantes',
  },
  {
    image: 'https://i.postimg.cc/Cxdc8TLT/14-Motocenter.png',
    title: 'Moto Center',
  },
  {
    image: 'https://i.postimg.cc/bvxkD3bf/15-Retifica.png',
    title: 'Retíficas',
  },
  {
    image: 'https://i.postimg.cc/FKTFBSXJ/16-Desmanche-Regular.png',
    title: 'Desmanches Regulares',
  },
  {
    image: 'https://i.postimg.cc/CxNfwzVw/17-Marina-e-Aeroclube.png',
    title: 'Marinas e Aeroclubes',
  },
  {
    image: 'https://i.postimg.cc/MpdXhNyT/18-Maquinas-e-Equipamentos-Agr-colas.png',
    title: 'Máquinas e Equipamentos Agrícolas',
  },
  {
    image: 'https://i.postimg.cc/mDML25F7/19-Servi-os-Manuais.png',
    title: 'Serviços Manuais',
  },
  {
    image: 'https://i.postimg.cc/QNmLkDsH/20-Assist-ncia-T-cnica.png',
    title: 'Assistência Técnica',
  },
  {
    image: 'https://i.postimg.cc/J766zzMc/21-Outros-Servi-os-Especializados.png',
    title: 'Outros Serviços Especializados',
  },
  
];

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1024 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 1024, min: 768 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 768, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const CompaniesProfileSection = () => {
  return (
    <section className="bg-blue-100 py-10">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold text-center mb-8">
          PERFIL DE EMPRESAS QUE <span className="bg-blue-500 text-white px-2 rounded-[8px]">ATENDEMOS</span>
        </h2>
        <Carousel responsive={responsive} infinite={true} autoPlay={true} autoPlaySpeed={2000} keyBoardControl={true} showDots={false}>
          {carouselItems.map((item, index) => (
            <div key={index} className="p-4">
              <div className=" p-6 rounded-lg">
                <img src={item.image} alt={item.title} className="rounded-lg mb-4" />
                <h3 className="text-lg font-semibold">{item.title}</h3>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </section>
  );
};

export default CompaniesProfileSection;

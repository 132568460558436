// src/components/BenefitsSection.js
import React from 'react';

const BenefitsSection = () => {
  return (
    <section className="py-10">
      <h2 className="text-3xl font-bold text-center mb-8">
          PRINCIPAIS VANTAGENS E <span className="bg-blue-500 text-white px-2 rounded-[8px]">BENEFÍCIOS</span>
        </h2>
      <div className="container mx-auto text-center">
        <img 
          src="https://i.postimg.cc/BvyHGcQK/Vantagens-e-Beneficios-Nova.png" 
          alt="Principais Vantagens e Benefícios" 
          className="mx-auto"
        />
      </div>
    </section>
  );
};

export default BenefitsSection;

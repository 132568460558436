// src/components/PricingPlans.js
import React, { useState } from 'react';
import PricingCard from './PricingCard';

const PricingPlans = () => {
  const [billingCycle, setBillingCycle] = useState('annually'); // Define 'annually' como padrão

  const plans = [
    {
      title: 'Básico',
      monthlyPrice: 49.99,
      annualPrice: 479.90,
      description: 'Plano grátis para todos os usuários',
      features: [
        'Gerenciamento simplificado: Cadastre clientes, veículos, produtos e serviços com facilidade.',
        'Compartilhamento via WhatsApp: Envie Ordens de Serviço (O.S.) diretamente para seus clientes.',
        'Aprovação de orçamento online: Seu cliente poderá aprovar remotamente o início do serviço',
        'Cobrança online: Receba pagamentos remotamente via cartão de crédito através de link de pagamento com taxas competitivas.',
        'Consulta de veículos: Obtenha informações completas do veículo cadastrado apenas com a placa.',
        'Lembrete de aniversário de seus clientes',
        'Integração com Peça Peças: Acesse um catálogo com mais de 7 milhões de peças automotivas.',
        'Controle financeiro: Gerencie contas a pagar, a receber, e tenha o controle de caixa na palma da sua mão.'
      ]
    },
    {
      title: 'Profissional',
      monthlyPrice: 139.99,
      annualPrice: 1343.90,
      description: 'Ideal para pequenas empresas.',
      features: [
        'Plano Básico: Inclui todas as funcionalidades essenciais do plano básico',
        'Acesso para equipe: Permite múltiplos logins simultâneos (até 3 usuários).',
        'Abertura de OS com checklist completo identificando as principais informações e fotos do veículo recebido',
        'Maquininha de cartão: Enviamos para você uma máquina de cartão com taxas competitivas para sua conveniência (consulte para mais detalhes).',
        'Relatórios completos: Visualize o desempenho de clientes, produtos e serviços mais vendidos. E muito mais!',
        'Área do cliente personalizada: Forneça a seu cliente o acompanhamento de serviços em andamento, serviços pendentes com lembretes e histórico completo de revisões já realizadas.',
        'Gestão de agenda: Veja em formato de calendário tudo o que acontece na sua empresa. Organize suas contas, datas importantes, agende serviços e crie lembretes automáticos.',
        'Emissão de notas fiscais: Nota de produto (NF-e) e nota de serviço (NFS-e) emitidas automaticamente pela plataforma após a criação de uma OS.',
        'Kanban 4K: Monitore e compartilhe o progresso dos serviços com seus funcionários e clientes em uma tela de TV.'
      ],
    },
    {
      title: 'Para empresas',
      monthlyPrice: 239.99,
      annualPrice: 2303.90,
      description: 'Funciona melhor para empresas empresariais.',
      features: [
        'O plano mais completo: Inclui todas as funcionalidades dos planos Básico e Profissional.',
        'Controle de acesso por colaborador: configure quais acessos cada colaborador deve ter no sistema.',
        'Acesso ampliado para equipes: Permite até 10 usuários cadastrados no sistema e com acesso a área logada (para mais usuários cadastrados, consulte nosso time comercial)',
        'Função PDV: Ideal para empresas que fazem venda de peças no balcão ou online, sem depender de um serviço ou veículo.',
        'Etiqueta de óleo personalizada: Solicite uma impressora térmica para etiquetas com QR Code para acesso à área do cliente.',
        'Garage Card exclusivo: Histórico do veículo acessível online para seus clientes, de forma personalizada (consulte valores adicionais)',
        'Peça Crédito: Linha de crédito para a compra de peças (sujeito a análise).',
        'Consulte nosso time para ferramentas de CRM, email marketing, sites próprios e integração com Mercado Livre ou criação do seu e-commerce próprio (consulte valores adicionais)'
      ]
    },
  ];

  return (
    <div className="container mx-auto py-10">
      <h1 className="text-3xl font-bold mb-6 bg-blue-500 text-white px-2 rounded-[8px] py-3 text-center">Planos e Preços GarageHero</h1>
      <div className="flex justify-center items-center mb-10">
        <span>Mensal</span>
        <label className="switch mx-2">
          <input
            type="checkbox"
            checked={billingCycle === 'annually'} // Define o estado do checkbox
            onChange={() => setBillingCycle(billingCycle === 'monthly' ? 'annually' : 'monthly')}
          />
          <span className="slider round"></span>
        </label>
        <span>Anualmente (20% de desconto)</span>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {plans.map((plan, index) => (
          <PricingCard key={index} {...plan} billingCycle={billingCycle} />
        ))}
      </div>
    </div>
  );
};

export default PricingPlans;

// src/components/Header.js
import React, { useState } from 'react';
import FlashComponent from './FlashComponent';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="bg-white shadow-md p-3">
      <FlashComponent />
      <div className="container mx-auto flex justify-between items-center py-4 px-4 mt-12">
        <div className="flex items-center">
          <a href="/"><img src="https://i.postimg.cc/8cQc4wFL/Logo-GH-Retangular.png" alt="Logo" className="h-16" /></a>
        </div>
        <div className="flex items-center space-x-4">
          <a href="https://sistema.garagehero.com.br/registrar" target="_blank" rel="noopener noreferrer">
            <span className="hidden md:block text-black">CADASTRE-SE E TESTE GRÁTIS</span>
          </a>
          <a href="https://sistema.garagehero.com.br/" target="_blank" rel="noopener noreferrer">
            <button className="bg-blue-500 text-white px-4 py-2 rounded">ENTRAR</button>
          </a>
          <div className="text-black cursor-pointer" onClick={toggleMenu}>
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
            </svg>
          </div>
        </div>
      </div>
      {menuOpen && (
        <div className="bg-white shadow-md absolute w-full left-0 z-10">
          <ul className="flex flex-col items-center space-y-4 py-4">
            <li><a href="/funcionalidades" className="text-black">Planos e Preços</a></li>
            <li><a href="/central-de-ajuda" className="text-black">Central de Ajuda</a></li>
            <li><a href="/contato" className="text-black">Contato</a></li>
          </ul>
        </div>
      )}
    </header>
  );
}

export default Header;

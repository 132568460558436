// src/components/HighlightsSection.js
import React from 'react';
import './HighlightsSection.css';

const highlights = [
  {
    id: 1,
    icon: (
      <svg className="w-12 h-12 mb-2" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 19.2C9.12 19.2 6.8 16.88 6.8 14C6.8 11.12 9.12 8.8 12 8.8C14.88 8.8 17.2 11.12 17.2 14C17.2 16.88 14.88 19.2 12 19.2ZM12 4.8C13.32 4.8 14.4 5.88 14.4 7.2C14.4 8.52 13.32 9.6 12 9.6C10.68 9.6 9.6 8.52 9.6 7.2C9.6 5.88 10.68 4.8 12 4.8Z" />
      </svg>
    ),
    title: "TEMOS MAIS DE 800 CLIENTES QUE CONFIAEM DIARIAMENTE EM NOSSA SOLUÇÃO"
  },
  {
    id: 2,
    icon: (
      <svg className="w-12 h-12 mb-2" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 19.2C9.12 19.2 6.8 16.88 6.8 14C6.8 11.12 9.12 8.8 12 8.8C14.88 8.8 17.2 11.12 17.2 14C17.2 16.88 14.88 19.2 12 19.2ZM12 4.8C13.32 4.8 14.4 5.88 14.4 7.2C14.4 8.52 13.32 9.6 12 9.6C10.68 9.6 9.6 8.52 9.6 7.2C9.6 5.88 10.68 4.8 12 4.8Z" />
      </svg>
    ),
    title: "AJUDAMOS NOSSOS CLIENTES NO PROCESSO DE AMADURECIMENTO PROFISSIONAL ATRAVÉS DO USO DE TECNOLOGIA"
  },
  {
    id: 3,
    icon: (
      <svg className="w-12 h-12 mb-2" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 19.2C9.12 19.2 6.8 16.88 6.8 14C6.8 11.12 9.12 8.8 12 8.8C14.88 8.8 17.2 11.12 17.2 14C17.2 16.88 14.88 19.2 12 19.2ZM12 4.8C13.32 4.8 14.4 5.88 14.4 7.2C14.4 8.52 13.32 9.6 12 9.6C10.68 9.6 9.6 8.52 9.6 7.2C9.6 5.88 10.68 4.8 12 4.8Z" />
      </svg>
    ),
    title: "ESTAMOS PRESENTES COM CLIENTES EM TODAS AS REGIÕES DO BRASIL"
  },
  {
    id: 4,
    icon: (
      <svg className="w-12 h-12 mb-2" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 19.2C9.12 19.2 6.8 16.88 6.8 14C6.8 11.12 9.12 8.8 12 8.8C14.88 8.8 17.2 11.12 17.2 14C17.2 16.88 14.88 19.2 12 19.2ZM12 4.8C13.32 4.8 14.4 5.88 14.4 7.2C14.4 8.52 13.32 9.6 12 9.6C10.68 9.6 9.6 8.52 9.6 7.2C9.6 5.88 10.68 4.8 12 4.8Z" />
      </svg>
    ),
    title: "TEMOS NOTA MÁXIMA DE AVALIAÇÃO DE NOSSOS CLIENTES"
  },
  {
    id: 5,
    icon: (
      <svg className="w-12 h-12 mb-2" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 19.2C9.12 19.2 6.8 16.88 6.8 14C6.8 11.12 9.12 8.8 12 8.8C14.88 8.8 17.2 11.12 17.2 14C17.2 16.88 14.88 19.2 12 19.2ZM12 4.8C13.32 4.8 14.4 5.88 14.4 7.2C14.4 8.52 13.32 9.6 12 9.6C10.68 9.6 9.6 8.52 9.6 7.2C9.6 5.88 10.68 4.8 12 4.8Z" />
      </svg>
    ),
    title: "OUVIMOS AS NECESSIDADES ESPECÍFICAS DE CADA CLIENTE PARA MELHORAR SEMPRE O SISTEMA"
  }
];

const HighlightsSection = () => {
  return (
    <section className="bg-white py-10">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold text-center mb-8">
          COMO NOS <span className="bg-blue-500 text-white px-2 rounded-[8px]">DESTACAMOS?</span>
        </h2>
        <div className="mx-auto">
         <img src="https://i.postimg.cc/VkzZS8Nc/Como-nos-destacamos.png" alt="" srcset="" />
        </div>
      </div>
    </section>
  );
}

export default HighlightsSection;
